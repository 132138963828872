import { useAuth, useEmailPasswordAuth } from "@realm/react";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import * as Realm from "realm-web";
import Loading from "../common/Loading";
import secureLocalStorage from "react-secure-storage";
import { useApplication } from "../../test/useApplication";
import AppContext from "../../context/AppProvider";
import { loginRequest } from "../../config/api";

const Login = ({ handleForgot }: { handleForgot: (a: string) => void }) => {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const { logIn } = useEmailPasswordAuth();
  const { logInWithJWT, result } = useAuth();

  const navigate = useNavigate();
  const { setProfileData } = useContext(AppContext);

  const { app } = useApplication();

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (!data.email || !data.password) return;

    try {
      setIsLoading(true);
      const res: any = await loginRequest({
        email: data.email,
        password: data.password,
      });

      if (res && res?.data) {
        setIsLoading(false);
        if (res?.data?.role == "admin") {
          localStorage.setItem("token", res?.token);
          localStorage.setItem("role", res?.data?.role);
          navigate("/website-users");
        } else if (res?.data?.role == "sales_member") {
          const credentials = Realm.Credentials.jwt(res?.token);
          logInWithJWT(res?.token);
          const user: any = await app.logIn(credentials);
          localStorage.setItem("token", res?.token);
          localStorage.setItem("role", res?.data?.role);
          navigate(`/calculator`);
        } else {
          navigate("/");
        }
      }
    } catch (err: any) {
      setIsLoading(false);
      toast.error(err?.message);
    }
  };

  return (
    <>
      <div className="logo">
        <a>
          <img src="images/Logo.png" alt="Logo" />
        </a>
      </div>
      <div className="login-input-details">
        <h2>Login</h2>
        <p>Enter your details to login to your account</p>
        <div className="input-group">
          <input
            type="email"
            onChange={handleChange}
            name="email"
            value={data.email}
            placeholder="youremail@example.com"
          />
          <span className="icon">
            <img src="images/mail_icon.svg" alt="" />
          </span>
        </div>
        <div className="input-group mb">
          <input
            type={passwordVisible ? "text" : "password"}
            onChange={handleChange}
            name="password"
            value={data.password}
            placeholder="Password"
          />
          <span className="icon">
            <img
              className="mobile-icon"
              src="images/password_icon.svg"
              alt=""
            />
          </span>
          <span
            className="eye-icon"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {" "}
            {passwordVisible ? <LuEye size={20} /> : <LuEyeOff size={20} />}
          </span>
        </div>
        <div
          className="input-group"
          onClick={() => handleForgot("ConfirmEmail")}
        >
          <a style={{ cursor: "pointer" }}>Forgot Password ?</a>
        </div>
        <div className="login-btn">
          <button onClick={handleSubmit} disabled={loading}>
            {loading ? <Loading /> : "LOGIN"}
          </button>
        </div>
        <div className="have-account">
          <p>
            Don't have an account?{" "}
            <a
              style={{ cursor: "pointer" }}
              onClick={() => handleForgot("signUp")}
            >
              Sign Up
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
