import React, { FC } from "react";
import { Table } from "semantic-ui-react";

interface DraggableTableRowProps {
  i: number;
  action: (targetIndex: number, sourceIndex: number) => void;
  children: React.ReactNode;
}

const DraggableTableRow: FC<DraggableTableRowProps> = ({
  i,
  action,
  children,
}) => {
  const onDragStart = (
    ev: React.DragEvent<HTMLTableRowElement>,
    index: number
  ) => {
    ev.dataTransfer.setData("index", index.toString());
  };

  const onDragOver = (ev: React.DragEvent<HTMLTableRowElement>) => {
    ev.preventDefault();
  };

  const onDrop = (
    ev: React.DragEvent<HTMLTableRowElement>,
    targetIndex: number
  ) => {
    const sourceIndex = parseInt(ev.dataTransfer.getData("index"), 10);
    action(targetIndex, sourceIndex);
  };

  return (
    <Table.Row
      draggable
      className="draggable data-table-row"
      onDragStart={(e: React.DragEvent<HTMLTableRowElement>) =>
        onDragStart(e, i)
      }
      onDragOver={(e: React.DragEvent<HTMLTableRowElement>) => onDragOver(e)}
      onDrop={(e: React.DragEvent<HTMLTableRowElement>) => onDrop(e, i)}
    >
      {children}
    </Table.Row>
  );
};

export default DraggableTableRow;
