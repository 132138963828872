import Pagination from "../../components/common/Pagination";
import Header from "../../components/common/Header";
import { useEffect, useState } from "react";
import { getWebSales } from "../../config/api";
import moment from "moment";
import PageLoading from "../../components/common/PageLoading";
import DataGrid from "../../admin/DataGrid";
import { BsThreeDots } from "react-icons/bs";
import Illustration from "../../components/common/Illustration";

const SalesWeb = () => {
  const formatDate = (dateString: any) => {
    const format = "DD-MM-YYYYTHH:mm:ss.SSSSZ"; // Adjust if the format is slightly different

    // Parse the date string with the specified format
    const momentDate = moment(dateString, format, true);
    if (!momentDate.isValid()) {
      return "Invalid Date"; // Fallback for invalid dates
    }

    return momentDate.tz("America/New_York").format("MM/DD/YYYY");
  };

  const formatTime = (dateString: string) => {
    const format = "DD-MM-YYYYTHH:mm:ss.SSSSZ";
    const momentDate = moment(dateString, format, true);
    if (!momentDate.isValid()) {
      return "Invalid Time";
    }
    return momentDate.local().format("h:mm A");
  };

  const columns = [
    {
      defaultFlex: 1,
      name: "tspId",
      header: "TSP ID",
      render: ({ data }: any) => <>{data.tspId ? data.tspId : "-"}</>,
      minWidth: 150,
    },
    {
      defaultFlex: 2,
      name: "storeName",
      render: ({ data }: any) => <>{data.storeName ? data.storeName : "-"}</>,
      header: "Store Name",
      minWidth: 150,
    },
    {
      defaultFlex: 3,
      name: "repName",
      render: ({ data }: any) => <>{data.repName ? data.repName : "-"}</>,
      header: "Rep Name",
      minWidth: 150,
    },
    {
      defaultFlex: 4,
      name: "repId",
      render: ({ data }: any) => <>{data.repId ? data.repId : "-"}</>,
      header: "Rep ID",
      minWidth: 150,
    },
    {
      defaultFlex: 5,
      name: "dateTime",
      header: "Date",
      render: ({ data }: any) => (
        <>{moment(data.dateTime).format("MM/DD/YYYY") || "-"}</>
      ),
      minWidth: 150,
    },
    {
      defaultFlex: 6,
      name: "dateTime",
      header: "Time",
      render: ({ data }: any) => (
        <>{moment(data.dateTime).format("hh:mm A") || "-"}</>
      ),
      minWidth: 150,
    },
    {
      defaultFlex: 7,
      name: "number",
      render: ({ data }: any) => <>{data.number ? data.number : "-"}</>,
      header: "Phone#",
      minWidth: 150,
    },
    {
      defaultFlex: 8,
      name: "modelSku",
      render: ({ data }: any) => <>{data.modelSku ? data.modelSku : "-"}</>,
      header: "Model/SKU",
      minWidth: 150,
    },
    {
      defaultFlex: 9,
      name: "imei",
      render: ({ data }: any) => <>{data.imei ? data.imei : "-"}</>,
      header: "IMEI",
      minWidth: 150,
    },
    {
      defaultFlex: 10,
      name: "sim",
      render: ({ data }: any) => <>{data.sim ? data.sim : "-"}</>,
      header: "Sim",
      minWidth: 150,
    },
    {
      defaultFlex: 11,
      name: "idVerified",
      render: ({ data }: any) => (
        <>{data.idVerified === false ? "No" : "Yes"}</>
      ),
      header: "I.D Veriff",
      minWidth: 150,
    },
    {
      defaultFlex: 12,
      name: "autoPay",
      render: ({ data }: any) => <>{data.autoPay === false ? "No" : "Yes"}</>,
      header: "AutoPay",
      minWidth: 150,
    },
    {
      defaultFlex: 13,
      name: "vpOrderNumber",
      render: ({ data }: any) => (
        <>{data.vpOrderNumber ? data.vpOrderNumber : "-"}</>
      ),
      header: "VP Order Number",
      minWidth: 150,
    },
    {
      defaultFlex: 14,
      name: "carrier",
      render: ({ data }: any) => <>{data.carrier ? data.carrier : "-"}</>,
      header: "Carrier",
      minWidth: 150,
    },
    {
      defaultFlex: 15,
      name: "port",
      render: ({ data }: any) => <>{data.port ? data.port : "-"}</>,
      header: "Port",
      minWidth: 150,
    },
    {
      defaultFlex: 16,
      name: "line",
      render: ({ data }: any) => <>{data.line ? data.line : "-"}</>,
      header: "Line#",
      minWidth: 150,
    },
    {
      defaultFlex: 17,
      name: "planValue",
      render: ({ data }: any) => <>${data.planValue ? data.planValue : "-"}</>,
      header: "Plan",
      minWidth: 150,
    },
    {
      defaultFlex: 18,
      name: "firstPayment",
      header: "First Payment",
      render: ({ data }: any) => (
        <>${data.firstPayment ? data.firstPayment : "-"}</>
      ),
      minWidth: 150,
    },
  ].map((col) => ({ ...col, visible: true }));
  const [salesData, setSalesData] = useState<any>({
    sales: [],
    total: 0,
    totalPages: 0,
  });
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [isLoading, setIsLoading] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [params, setParams] = useState({
    page: 1,
    perPage: 10,
  });

  const handleColumnChange = (column: any, isVisible: any) => {
    setVisibleColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.name === column.name ? { ...col, visible: isVisible } : col
      )
    );
  };

  const pageHandler = (e: any) => {
    const page = e.selected + 1;
    setParams({ ...params, page });
  };

  const handlePageSelect = (value: number) => {
    setParams({ ...params, perPage: value });
  };

  const [actionToggle, setActionToggle] = useState(false);

  const handleEyeButtonClick = (data: any) => {
    // setActionToggle(!actionToggle)
    setActionToggle(true);
  };

  useEffect(() => {
    const fetchSaleListing = async () => {
      setIsLoading(true);
      try {
        const response: any = await getWebSales(
          params.page,
          params.perPage,
          searchString
        );
        setSalesData(response.data || { sales: [], total: 0, totalPages: 0 });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    fetchSaleListing();
  }, [searchString, params]);

  return (
    <>
      <Header
        title={"Sale"}
        backButton={null}
        backHandle={null}
        admin={false}
      />
      <div className="loaction-list-main web-sales-main">
        <div className="loaction-list-head">
          <div className="search-bar">
            <input
              type="search"
              placeholder="Search by name"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            <img src="images/search_icon.svg" alt="" />
          </div>
        </div>

        <div className="table-data-main sales-entry-table-head web-sales-entry">
          <div className="table-data">
            {/* <DataGrid
              dataSource={salesData?.sales}
              visibleColumns={visibleColumns}
            /> */}
            {isLoading ? (
              <PageLoading />
            ) : salesData?.sales?.total == 0 ? (
              <Illustration
                imgLink={"/images/noIncident.svg"}
                title="No Incidents Yet!"
              />
            ) : (
              <DataGrid
                dataSource={salesData?.sales}
                visibleColumns={visibleColumns}
              />
            )}
            {/* <table border={0} cellPadding={0} style={{ width: "100%" }}>
              <thead
                style={{
                  backgroundColor: "#fdfdfd",
                  borderBottom: "1px solid #ededed",
                  borderTop: "1px solid #ededed",
                }}
              >
                <tr>
                  <th className="heading">TSP ID</th>
                  <th className="heading">Store Name</th>
                  <th className="heading">Rep Name</th>
                  <th className="heading">Rep ID</th>
                  <th className="heading">Date</th>
                  <th className="heading">Time</th>
                  <th className="heading">Phone#</th>
                  <th className="heading">Model/SKU</th>
                  <th className="heading">IMEI</th>
                  <th className="heading">SIM</th>
                  <th className="heading">ID. Veriff</th>
                  <th className="heading">Autopay</th>
                  <th className="heading">VP Order Number</th>
                  <th className="heading">Carrier</th>
                  <th className="heading">Port</th>
                  <th className="heading">Line#</th>
                  <th className="heading">Plan</th>
                  <th className="heading">First Payment</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={100}>
                      <div
                        className="sales-loader"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "10vh",
                        }}
                      >
                        <PageLoading />
                      </div>
                    </td>
                  </tr>
                ) : salesData?.sales?.length === 0 ? (
                  <tr>
                    <td colSpan={100}>
                      <div
                        className="sales-loader"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "10vh",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "10vh",
                          }}
                        >
                          <img src="/images/no-sales.png" alt="" />
                          <span style={{ fontSize: "22px", fontWeight: "600" }}>
                            No Sales Yet
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  salesData.sales?.map((sale: any, index: any) => (
                    <tr
                      key={index}
                      style={{ borderBottom: "1px solid #ededed" }}
                    >
                      <td className="table-col">{sale.tspId || "-"}</td>
                      <td className="table-col">{sale.storeName || "-"}</td>
                      <td className="table-col">{sale.repName || "-"}</td>
                      <td className="table-col">{sale.repId || "-"}</td>
                      <td className="table-col">
                        {moment(sale.dateTime).format("DD/MM/YYYY") || "-"}
                      </td>
                      <td className="table-col">
                        {moment(sale.dateTime).format("hh:mm A") || "-"}
                      </td>
                      <td className="table-col">{sale.number || "-"}</td>
                      <td className="table-col">{sale.modelSku || "-"}</td>
                      <td className="table-col">{sale.imei || "-"}</td>
                      <td className="table-col">{sale.sim || "-"}</td>
                      <td className="table-col">
                        {sale.idVerified === false ? "No" : "Yes"}
                      </td>
                      <td className="table-col">
                        {sale.autoPay === false ? "No" : "Yes"}
                      </td>
                      <td className="table-col">{sale.vpOrderNumber || "-"}</td>
                      <td className="table-col">{sale.carrier || "-"}</td>
                      <td className="table-col">{sale.port || "-"}</td>
                      <td className="table-col">{sale.line || "-"}</td>
                      <td className="table-col">${sale.planValue || "-"}</td>
                      <td className="table-col">{sale.firstPayment || "-"}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table> */}
          </div>
        </div>
      </div>

      {salesData?.sales?.length !== 0 && (
        <Pagination
          paramsData={params}
          total={salesData?.total}
          pageCount={salesData?.totalPages}
          handlePageChange={pageHandler}
          handlePageSelect={handlePageSelect}
          increasePageSize={true}
        />
      )}
    </>
  );
};

export default SalesWeb;
