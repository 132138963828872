import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Header from "../../components/common/Header";
import Loading from "../../components/common/Loading";
import ChangePassword from "../../components/popUps/ChangePassword";
import { changePasswordRequest, updateCustomerData } from "../../config/api";
import AppContext from "../../context/AppProvider";
import { useApplication } from "../../test/useApplication";
import { textEllipsis } from '../../utils/common';

const WebSettings = () => {
    const [changePassword , setChangePassword] = useState(false)
    const {app} = useApplication()
    const [data , setData] = useState<any>({})
    const [formData, setFormData] = useState<any>({
        firstName: '',
        lastName: '',
        number: '',
        email:"",
        storeName:"",
        zipCode:"",
        tspId:"",
    });
    const [file, setProfilePicture] = useState<any>()
    const [selectedImage, setSelectedImage] = useState<any>()
    const [loading, setIsLoading] = useState<boolean>(false)
    const [passwordLoading, setPasswordIsLoading] = useState<boolean>(false)
    const [pageLoading, setPageLoading] = useState<boolean>(false)
    const [positionToggle, setPositionToggle] = useState(false)
    const [position, setPosition] = useState("")
    const [positionShortName, setPositionShortName] = useState("")

    const roles = [
        { title: "Dealer/Owner", abbreviation: "DLR" },
        { title: "District Manager", abbreviation: "DM" },
        { title: "Retail Store Manager", abbreviation: "RSM" },
        { title: "Retail Store Assistant Manager", abbreviation: "RAM" },
        { title: "Retail Store Associate", abbreviation: "RSA" },
        { title: "Operations", abbreviation: "OPR" }
      ];
    

    const navigate = useNavigate()

    const  {firstName , lastName , number , storeName , tspId , zipCode} = formData
    
    

    const {profileData , setProfileData} = useContext(AppContext)
    useEffect(() => {
        if(profileData){
            setFormData({
                firstName: profileData?.firstName,
                lastName: profileData?.lastName,
                number: profileData?.number,
                email:profileData?.email,
                storeName:profileData?.storeName,
                zipCode:profileData?.zipCode,
                tspId:profileData?.tspId,

            })
            setPosition(profileData?.position)
            setPositionShortName(profileData?.positionShortName)
            setSelectedImage(profileData?.image)
        }
       
    }, [profileData])

 
    const handleClose = ()=>{
        setChangePassword(false)
    }

    useEffect(() => {
        function handleClickOutside(event: any) {
    
          const id = event.target.id
          if (!id) {
            setPositionToggle(false)
          }
        }
        document.addEventListener('click', handleClickOutside);
    
        return () => {
    
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

   
    //* profile change 
    const handleImage = (event: any) => {
        const file = event.target?.files[0]
        if (file) {
            setSelectedImage(file)
            
        }
    }

    const handleChange = (e:any) => {
        const {name , value} = e.target;
        if (name == "number") {
            if (value.length > 10) return
          }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const passwordHandler = async (data:any)=>{
        try {
            
            setPasswordIsLoading(true)
          const res:any =  await changePasswordRequest(data)
          if(res?.status){
            setChangePassword(false)
            setPasswordIsLoading(false)
            toast.success("Password updated successfully")
          }
      
          } catch (error:any) {
            toast.error(error?.message)
            setPasswordIsLoading(false)
            console.log("error" , error)
          }
    }

  const defaultImage = "images/img_placeholder.png";



    const handleProfile = async ()=>{
        if( !firstName || !lastName || !number  || !storeName || !tspId || !zipCode){
            return toast.error("All fields required!")
        }
        setIsLoading(true)
        try {
            const formData = new FormData()
            formData.append("firstName" , firstName)
            formData.append("lastName" , lastName)
            formData.append("number" , number)
            formData.append("position" , position)
            formData.append("tspId" , tspId)
            formData.append("storeName" , storeName)
            formData.append("positionShortName" , positionShortName)
            formData.append("zipCode" , zipCode)
            if(typeof selectedImage == "string"){
                formData.append("oldImageUrl" , selectedImage)
            }
            if( typeof selectedImage === "object"){
                formData.append("newCustomerImage" , selectedImage)
            }
            const res:any = await updateCustomerData(formData)
            if(res?.status){
                setProfileData(res?.data)
                toast.success(res?.message)
                setIsLoading(false)
            }
                       
        } catch (error) {
            setIsLoading(false)
            toast.error("Something went wrong")
            console.log("error" , error)
        }

    }

    const handleSelect = (value:any)=>{
        setPosition(value.title)
        setPositionShortName(value.abbreviation)
        setPositionToggle(false)
      }

      const isButtonShow = firstName != profileData?.firstName || lastName != profileData?.lastName ||  number != profileData?.number || position != profileData?.position || formData.storeName != profileData?.storeName || formData.zipCode != profileData?.zipCode || formData.tspId != profileData?.tspId || typeof selectedImage === "object"

     
   
  return (
    <>
    <Header title={'Settings'} backButton={null} backHandle={null} admin={false} /> 
    {changePassword && (
        <ChangePassword
          loading={passwordLoading}
          handleClose={handleClose}
          passwordHandler={passwordHandler}
        />
      )}
    <div className="loaction-list-main">
                <div className="setting-main web-setting-details">
                    <div className="setting-details">
                        <h2>Basic Details</h2>
                        <p>view & manage your basic profile details for the admin account</p>
                        <div className="basic-detail">
                            <div className="profile-upload">
                                <div className="profile-img">
                                  <img
                                      src={
                                          (selectedImage == null || selectedImage == "")
                                              ? defaultImage
                                              : typeof selectedImage === "object"
                                                  ? URL.createObjectURL(selectedImage)
                                                  : selectedImage
                                      }
                                      alt=""
                                  />
                                    <div className="input-file">
                                        <input type="file" onChange={handleImage} />

                                        <img src="images/setting_edit_icon.svg" alt="" />
                                    </div>
                                </div>
                                <label>Update Picture</label>
                            </div>
                            <div className="profile-details">
                                <div className="input-group">
                                    <div className="input-field">
                                        <label>First Name</label>
                                        <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="First Name" />

                                    </div>
                                    <div className="input-field">
                                        <label>Last Name</label>
                                        <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Last Name" />

                                    </div>
                                    <div className="input-field">
                                        <label>Email</label>
                                        <input type="email" name="email" value={formData.email} disabled onChange={handleChange} placeholder="abcde@gmail.com" id="" />

                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-field">
                                        <label>Phone Number</label>
                                        <input type="number" name="number" value={formData.number}  onChange={handleChange} placeholder="Phone number" />

                                    </div>
                                    <div className="input-field">
                                        <label>Position</label>
                                        <div className='position-dropdown' style={{position:"relative"}}>
                                        <button id={"btn"} onClick={() => setPositionToggle(!positionToggle)}>{textEllipsis(position , 25)} <span>{positionShortName}</span></button>
                                      {
                                          positionToggle && (
                                              <div className="dropdown-list-main">
                                                  <ul>
                                                      {roles.map((value , index:any) => (
                                                          <li id={index} key={value.abbreviation} onClick={() => handleSelect(value)}>
                                                              <span>{value.title}</span>
                                                              <span>{value.abbreviation}</span>
                                                          </li>
                                                      ))}
                                                  </ul>
                                              </div>
                                          )
                                      }
                                        </div>
                                      
                                    </div>
                                    <div className="input-field">
                                        <label>Store Name</label>
                                        <input type="text" placeholder="storeName" name="storeName" onChange={handleChange} value={formData.storeName}  />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-field">
                                        <label>Store ZIP Code</label>
                                        <input type="text" placeholder="Zip Code" name='zipCode' onChange={handleChange} value={formData.zipCode} />
                                    </div>
                                    <div className="input-field">
                                        <label>TSP ID</label>
                                        <input type="text" placeholder="TSP ID" name="tspId" onChange={handleChange} value={formData.tspId} />
                                    </div>
                                    <div className="input-field">
                                        <label>Password</label>
                                        <div className="password-change">
                                            <input type="password"  style={{backgroundColor: "#F6F6F6" ,  border: "none" }} placeholder="xxxxxxxxxxxxx" />
                                             <button  onClick={()=>setChangePassword(true)}>Change</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="save-btn">
                            {
                                isButtonShow && <button onClick={handleProfile} disabled={loading}>{loading ? <Loading/> : "Save"}</button>
                            }
                        
                        </div>
                    </div>
                </div>
            </div>
      
    </>
  );
}

export default WebSettings;
