import { Fragment, useEffect, useRef, useState } from "react";
import Header from "../../components/common/Header";
import { useNavigate } from "react-router-dom";
import {
  addWebSalesEntry,
  getWebCarrier,
  getWebModel,
  getWebPlans,
  getWebPort,
  grammarPrompt,
} from "../../config/api";
import Loading from "../../components/common/Loading";
import toast from "react-hot-toast";
import grammarIcon from "../../assets/svg/grammaricon.svg";
import { useBlocker } from "../../utils/useBlocker";

const NewActivation = () => {
  const [grammarLoading, setGrammarLoading] = useState(false);
  const initialFormData = {
    autopay: false,
    carrier: {
      id: "",
      name: "",
    },
    idVerify: false,
    imei: "",
    line: "",
    modelSku: {
      id: "",
      name: "",
    },
    vpOrderNumber: "",
    monthly: "",
    notes: "",
    number: "",
    pins: "",
    plan: {
      id: "",
      name: "",
      value: "",
    },
    port: {
      id: "",
      name: "",
    },
    sim: "",
    today: "",
    type: "",
    planNameType: "",
  };

  const [lines, setLines] = useState([initialFormData]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(-1);
  const [activeName, setActiveName] = useState<string>("");
  const [searchString, setSearchString] = useState<string>("");
  const [planSearchString, setPlanSearchString] = useState<string>("");
  const [portSearchString, setPortSearchString] = useState<string>("");
  const [carrierSearchString, setCarrierSearchString] = useState<string>("");
  const [carriers, setCarriers] = useState<any[]>([]);
  const [models, setModels] = useState<any[]>([]);
  const [plans, setPlans] = useState<any[]>([]);
  const [ports, setPorts] = useState<any[]>([]);
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const planInputRef = useRef<HTMLInputElement | null>(null);
  const portInputRef = useRef<HTMLInputElement | null>(null);
  const carrierInputRef = useRef<HTMLInputElement | null>(null);
  const firstItemRef = useRef<HTMLLIElement | null>(null);
  const [selectedPlanValue, setSelectedPlanValue] = useState<number | null>(
    null
  );
  console.log(planSearchString, "------ ");
  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setActiveDropdown(-1);
      setActiveName("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const carriersResponse: any = await getWebCarrier(carrierSearchString);
        setCarriers(carriersResponse.data || []);
        const modelsResponse: any = await getWebModel(searchString);
        setModels(modelsResponse.data || []);
        const plansResponse: any = await getWebPlans(planSearchString);
        setPlans(plansResponse.data || []);
        const portsResponse: any = await getWebPort(portSearchString);
        setPorts(portsResponse.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [searchString, planSearchString, portSearchString, carrierSearchString]);

  const handleChange = (e: any, index: number) => {
    const { name, value, type, checked } = e.target;
    setActiveDropdown(-1);
    setActiveName("");
    const updatedLines: any = [...lines];

    if (name === "plan") {
      setSelectedPlanValue(value);
    }

    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      updatedLines[index] = {
        ...updatedLines[index],
        [parent]: {
          ...updatedLines[index][parent],
          [child]: type === "checkbox" ? checked : value,
        },
      };
    } else {
      updatedLines[index] = {
        ...updatedLines[index],
        [name]: type === "checkbox" ? checked : value,
      };
    }

    setLines(updatedLines);
    setIsDirty(true);
  };
  const validateForm = () => {
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];

      // if (!line.plan || line.plan.name.trim() === "") {
      //   toast.error(`Plan is required for line ${i + 1}.`);
      //   return false;
      // }
      if (!line.number || line.number.trim() === "") {
        toast.error(`Phone Number is required for line ${i + 1}.`);
        return false;
      }
      if (line.number.trim().length !== 10) {
        toast.error(`Number must be exactly 10 digits for line ${i + 1}.`);
        return false;
      }
      if (!line.modelSku || line.modelSku.name.trim() === "") {
        toast.error(`Model SKU is required for line ${i + 1}.`);
        return false;
      }
      if (!line.imei || line.imei.trim() === "") {
        toast.error(`IMEI is required for line ${i + 1}.`);
        return false;
      }
      if (!line.sim || line.sim.trim() === "") {
        toast.error(`SIM is required for line ${i + 1}.`);
        return false;
      }
      if (!line.carrier || line.carrier.name?.trim() === "") {
        toast.error(`Carrier is required for line ${i + 1}.`);
        return false;
      }
      if (!line.port || line.port.name?.trim() === "") {
        toast.error(`Port is required for line ${i + 1}.`);
        return false;
      }
      // if (!line.idVerify && !line.autopay) {
      //   toast.error(`Either ID Verification or Autopay is required for line ${i + 1}.`);
      //   return false;
      // }
      if (isNaN(parseFloat(line.today)) || parseFloat(line.today) < 0) {
        toast.error(`Today's amount must be a valid number for line ${i + 1}.`);
        return false;
      }
      if (isNaN(parseFloat(line.monthly)) || parseFloat(line.monthly) < 0) {
        toast.error(`Monthly amount must be a valid number for line ${i + 1}.`);
        return false;
      }
      if (!line.vpOrderNumber) {
        toast.error(`VP Order Number is required for line ${i + 1}.`);
        return false;
      }
      // if (!line.pins || line.pins.trim() === "") {
      //   toast.error(`PINs are required for line ${i + 1}.`);
      //   return false;
      // }
      // if (!line.notes || line.notes.trim() === "") {
      //   toast.error(`Notes are required for line ${i + 1}.`);
      //   return false;
      // }
      setIsFilled(true);
    }

    return true;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsDirty(false);
    setIsButtonLoading(true);
    try {
      const data = lines.map((line, idx) => ({
        autopay: line.autopay,
        carrier: line.carrier,
        idVerify: line.idVerify,
        imei: line.imei,
        line: (idx + 1).toString(),
        modelSku: line.modelSku,
        monthly: parseFloat(line.monthly.toString()) || 0,
        notes: line.notes,
        vpOrderNumber: line.vpOrderNumber,
        number: line.number,
        pins: line.pins,
        plan: line.plan,
        port: line.port,
        sim: line.sim,
        today: parseFloat(line.today.toString()) || 0,
        type: "newActivation",
      }));

      const response: any = await addWebSalesEntry({ sales: data });
      console.log("Sales Entry Updated:", response.data);
      navigate("/website-sales");
      setIsButtonLoading(false);
    } catch (error) {
      console.error("Error updating sales entry:", error);
      setIsButtonLoading(false);
    } finally {
      setIsButtonLoading(false);
      setIsDirty(true);
    }
  };

  const addLine = () => {
    setLines([...lines, initialFormData]);
  };

  const handleBack = () => {
    navigate("/sale-entry");
  };

  const handleOpenDropdown = (index: number, name: string) => {
    setActiveDropdown(index);
    setActiveName(name);
  };

  const handleOpenDropdownFocus = (index: number, name: string) => {
    if (activeDropdown !== index || activeName !== name) {
      setActiveDropdown(index);
      setActiveName(name);

      setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }, 0);
      setTimeout(() => {
        if (planInputRef.current) {
          planInputRef.current.focus();
        }
      }, 0);
      setTimeout(() => {
        if (portInputRef.current) {
          portInputRef.current.focus();
        }
      }, 0);
      setTimeout(() => {
        if (carrierInputRef.current) {
          carrierInputRef.current.focus();
        }
      }, 0);
    }
  };

  const handleGrammar = async () => {
    try {
      setGrammarLoading(true);
      const updatedLines = await Promise.all(
        lines.map(async (line) => {
          if (line.notes) {
            try {
              const res: any = await grammarPrompt(line.notes);
              if (res?.status) {
                return { ...line, notes: res?.message || line.notes };
              }
            } catch (error) {
              console.error("Error processing line notes:", error);
              return line;
            }
          }
          return line;
        })
      );
      setLines(updatedLines);
    } catch (error) {
      console.error("Error in handleGrammar:", error);
    } finally {
      setGrammarLoading(false);
    }
  };

  const [isDirty, setIsDirty] = useState(false);

  const handleBlock = () => {
    if (isDirty) {
      return !window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );
    }
    return false;
  };

  useBlocker(handleBlock, isDirty);

  return (
    <Fragment>
      <Header
        title={"New Activation"}
        backButton={true}
        backHandle={handleBack}
        admin={false}
      />
      {lines.map((formData, index) => (
        <div
          className="loaction-list-main add-plan-main activation-main activation-main-new"
          key={index}
        >
          <div className="loaction-list-head">
            <h3>Line {index + 1}</h3>
            {index > 0 && (
              <button
                type="button"
                onClick={() => setLines(lines.filter((_, i) => i !== index))}
              >
                Remove
              </button>
            )}
          </div>
          <form onSubmit={handleSubmit} className="add-plan-detail">
            <div className="line-section">
              <div className="input-group">
                <div className="input-field">
                  <label>Plan</label>
                  <button
                    type="button"
                    onFocus={() => handleOpenDropdownFocus(index, "Plan")}
                    onClick={() => handleOpenDropdown(index, "Plan")}
                  >
                    {formData.plan.value ? `$${formData.plan.value}` : "Select"}
                    <img src="images/angle_down_grey.svg" alt="dropdown" />
                  </button>
                  {activeDropdown === index && activeName === "Plan" && (
                    <div
                      className="carrier-search-main active"
                      ref={dropdownRef}
                    >
                      <div className="search-bar">
                        <input
                          type="search"
                          placeholder="Search Plan Value"
                          name="searchString"
                          value={planSearchString}
                          onChange={(e) => setPlanSearchString(e.target.value)}
                          ref={planInputRef}
                        />
                      </div>
                      <ul>
                        {plans.map((plan) => (
                          <li
                            key={plan.id}
                            onClick={() =>
                              handleChange(
                                {
                                  target: {
                                    name: "plan",
                                    value: {
                                      id: plan.id,
                                      name: plan.name,
                                      value: plan.value,
                                    },
                                  },
                                },
                                index
                              )
                            }
                          >
                            ${plan?.value}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                {/* Phone */}
                <div className="input-field">
                  <label>
                    Phone Number <sup className="required">*</sup>
                  </label>
                  <input
                    type="text"
                    name="number"
                    placeholder=""
                    value={formData.number}
                    onChange={(e) => handleChange(e, index)}
                    maxLength={10}
                    pattern="\d*"
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      target.value = target.value.replace(/[^0-9]/g, "");
                    }}
                  />
                </div>

                {/* Model/SKU */}
                <div className="input-field">
                  <label>
                    Model/SKU <sup className="required">*</sup>
                  </label>
                  <button
                    type="button"
                    onFocus={() => handleOpenDropdownFocus(index, "Model/SKU")}
                    onClick={() => handleOpenDropdown(index, "Model/SKU")}
                  >
                    {formData.modelSku.name || "Select"}{" "}
                    <img src="images/angle_down_grey.svg" alt="dropdown" />
                  </button>
                  {activeDropdown === index && activeName === "Model/SKU" && (
                    <div
                      className="carrier-search-main active"
                      ref={dropdownRef}
                    >
                      <div className="search-bar">
                        <input
                          type="search"
                          placeholder="Search Model, SKU, UPC"
                          name="searchString"
                          value={searchString}
                          onChange={(e) => setSearchString(e.target.value)}
                          ref={searchInputRef}
                        />
                      </div>
                      <ul>
                        {models.map((model) => (
                          <li
                            key={model.id}
                            onClick={() =>
                              handleChange(
                                {
                                  target: {
                                    name: "modelSku",
                                    value: { id: model.id, name: model.name },
                                  },
                                },
                                index
                              )
                            }
                          >
                            {model.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                {/* IMEI */}
                <div className="input-field">
                  <label>
                    IMEI <sup className="required">*</sup>
                  </label>
                  <input
                    type="number"
                    name="imei"
                    placeholder="3576963921923456"
                    value={formData.imei}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>

                {/* SIM */}
                <div className="input-field">
                  <label>
                    SIM <sup className="required">*</sup>
                  </label>
                  <input
                    type="number"
                    name="sim"
                    placeholder="890122228837989876"
                    value={formData.sim}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>

                {/* Carrier */}
                <div className="input-field">
                  <label>
                    Carrier <sup className="required">*</sup>
                  </label>
                  <button
                    type="button"
                    onFocus={() => handleOpenDropdownFocus(index, "Carrier")}
                    onClick={() => handleOpenDropdown(index, "Carrier")}
                  >
                    {formData.carrier.name || "Select"}
                    <img src="images/angle_down_grey.svg" alt="dropdown" />
                  </button>
                  {activeDropdown === index && activeName === "Carrier" && (
                    <div
                      className="carrier-search-main active"
                      ref={dropdownRef}
                    >
                      <div className="search-bar">
                        <input
                          type="search"
                          placeholder="Search Carrier"
                          name="searchString"
                          value={carrierSearchString}
                          onChange={(e) =>
                            setCarrierSearchString(e.target.value)
                          }
                          ref={carrierInputRef}
                        />
                      </div>
                      <ul>
                        {carriers.map((carrier) => (
                          <li
                            key={carrier.id}
                            onClick={() =>
                              handleChange(
                                {
                                  target: {
                                    name: "carrier",
                                    value: {
                                      id: carrier.id,
                                      name: carrier.name,
                                    },
                                  },
                                },
                                index
                              )
                            }
                          >
                            {carrier.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                {/* Port */}
                <div className="input-field">
                  <label>
                    Port? <sup className="required">*</sup>
                  </label>
                  <button
                    type="button"
                    onFocus={() => handleOpenDropdownFocus(index, "Port?")}
                    onClick={() => handleOpenDropdown(index, "Port?")}
                  >
                    {formData.port.name || "Select"}
                    <img src="images/angle_down_grey.svg" alt="dropdown" />
                  </button>
                  {activeDropdown === index && activeName === "Port?" && (
                    <div
                      className="carrier-search-main active"
                      ref={dropdownRef}
                    >
                      <div className="search-bar">
                        <input
                          type="search"
                          placeholder="Search Carrier"
                          name="searchString"
                          value={portSearchString}
                          onChange={(e) => setPortSearchString(e.target.value)}
                          ref={portInputRef}
                        />
                      </div>
                      <ul>
                        {ports.map((port, idx) => (
                          <li
                            key={port.id}
                            ref={idx === 0 ? firstItemRef : null}
                            tabIndex={0}
                            onClick={() =>
                              handleChange(
                                {
                                  target: {
                                    name: "port",
                                    value: { id: port.id, name: port.name },
                                  },
                                },
                                index
                              )
                            }
                          >
                            {port.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="input-field">
                  <label>
                    VP Order Number <sup className="required">*</sup>
                  </label>
                  <input
                    type="text"
                    name="vpOrderNumber"
                    placeholder=""
                    value={formData.vpOrderNumber}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>

                {/* Financial fields */}
                <div className="input-field">
                  <label>
                    Today <sup className="required">*</sup>
                  </label>
                  <div className="input-wrapper">
                    <span className="dollar-symbol">$</span>
                    <input
                      type="number"
                      name="today"
                      placeholder=""
                      value={formData.today}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </div>
                </div>
                <div className="input-field">
                  <label>
                    Monthly <sup className="required">*</sup>
                  </label>
                  <div className="input-wrapper">
                    <span className="dollar-symbol">$</span>
                    <input
                      type="number"
                      name="monthly"
                      placeholder=""
                      value={formData?.monthly}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </div>
                </div>

                <div className="input-field">
                  <label>Pin</label>
                  <input
                    type="number"
                    name="pins"
                    placeholder="123456"
                    value={formData.pins}
                    onChange={(e) => handleChange(e, index)}
                    maxLength={6}
                  />
                </div>

                <div className="input-field">
                  <label>Notes</label>
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      name="notes"
                      placeholder="Enter"
                      value={formData.notes}
                      onChange={(e) => handleChange(e, index)}
                    />
                    <span
                      onClick={handleGrammar}
                      style={{
                        position: "absolute",
                        top: "50%",
                        cursor: "pointer",
                        right: "15px",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {grammarLoading ? (
                        <span>
                          <Loading />{" "}
                        </span>
                      ) : (
                        <img
                          src={grammarIcon}
                          alt="grammar icon"
                          height={"25px"}
                        />
                      )}
                    </span>
                  </div>
                </div>
                <div className="input-field check-field">
                  <div className="check-input">
                    <label>I.D Veriff</label>
                    <input
                      type="checkbox"
                      name="idVerify"
                      checked={formData.idVerify}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </div>
                  <div className="check-input">
                    <label>AutoPay</label>
                    <input
                      type="checkbox"
                      name="autopay"
                      checked={formData.autopay}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Add line and submit buttons */}
            {lines.length === index + 1 && (
              <div className="submit-btns">
                <button type="button" onClick={addLine} disabled={isFilled}>
                  ADD A LINE
                </button>
                <button type="submit" className="save-btn" disabled={isFilled}>
                  {isButtonLoading ? <Loading /> : "SUBMIT"}
                </button>
              </div>
            )}
          </form>
        </div>
      ))}
    </Fragment>
  );
};

export default NewActivation;
