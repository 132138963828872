import React, { useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

type Navigator = {
  push: (path: string, state?: any) => void;
  replace: (path: string, state?: any) => void;
};

export const useBlocker = (blocker: () => boolean, when: boolean) => {
  const { navigator } = React.useContext(NavigationContext) as {
    navigator: Navigator;
  };

  useEffect(() => {
    if (!when) return;

    const originalPush = navigator.push;
    const originalReplace = navigator.replace;

    navigator.push = (path: string, state?: any) => {
      if (!blocker()) {
        originalPush(path, state);
      }
    };

    navigator.replace = (path: string, state?: any) => {
      if (!blocker()) {
        originalReplace(path, state);
      }
    };

    return () => {
      navigator.push = originalPush;
      navigator.replace = originalReplace;
    };
  }, [blocker, navigator, when]);
};
