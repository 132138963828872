import React, { useState, Fragment, useContext, useEffect, useRef } from "react";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import AppContext from "../../context/AppProvider";
import { addKittTypes, addModelTypes, addSalesEntry, getKittTypes, getModelSKUTypes, getSalesEntryDetail, updateSalesEntryDetail } from "../../config/api";
import PageLoading from "./PageLoading";
import moment from "moment";
import Loading from "./Loading";
import toast, { Toaster } from "react-hot-toast";

const AddModelForm = () => {
  const [formData, setFormData] = useState({
    commonName: "",
    name: "",
    sku: "",
    upc: "",
    addType: "",
    addKittType: "",
    productId: "",
    kitt: "",
    type: ""
  });
  const { setActiveIndex, salesId } = useContext(AppContext)
  const location = useLocation();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [kittDropDownOpen, setKittDropDownOpen] = useState(false);
  const [modelTypes, setModelTypes] = useState([]);
  const [kittTypes, setKittTypes] = useState([]);
  const [isTypeLoading, setIsTypeLoading] = useState(false);
  const [formattedTitle, setFormattedTitle] = useState<string>("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const path = location.pathname.split("/").pop();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownKittRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: MouseEvent) => {
    // Check if the click is outside both the type and kitt dropdowns
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropDownOpen(false);
    }
  
    if (dropdownKittRef.current && !dropdownKittRef.current.contains(event.target as Node)) {
      console.log("Closing Kitt dropdown because click was outside");
      setKittDropDownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    if (!formData?.commonName || formData?.commonName.trim() === "") {
      toast.error("Common Name is required.");
      return false;
    }
    if (!formData?.name || formData?.name.trim() === "") {
      toast.error("Name is required.");
      return false;
    }
    if (!formData?.sku || formData?.sku.trim() === "") {
      toast.error("SKU is required.");
      return false;
    }
    if (!formData?.upc || formData?.upc.trim() === "") {
      toast.error("UPC is required.");
      return false;
    }
    if (!formData?.productId || formData?.productId.trim() === "") {
      toast.error("Product ID is required.");
      return false;
    }
    if (!formData?.kitt || formData?.kitt.trim() === "") {
      toast.error("Kitt is required.");
      return false;
    }
    if (!formData?.type || formData?.type.trim() === "") {
      toast.error("Type is required.");
      return false;
    }
  
    return true;
  };

  
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    if (formattedTitle === "Edit") {
      setIsButtonLoading(true);
      try {
        const data = {
          modelSku: {
            commonName: formData?.commonName,
            kitt: formData?.kitt,
            productId: formData?.productId,
            sku: formData?.sku,
            upc: formData?.upc,
            name: formData?.name,
            type: formData?.type,
          },
          type: "Model/SKU"
        };
        const response: any = await updateSalesEntryDetail(salesId, data);
        console.log("Sales Entry Updated:", response.data);
        setIsButtonLoading(false);
        setActiveIndex(1);
        navigate("/sales");
      } catch (error) {
        console.error("Error updating sales entry:", error);
        setIsButtonLoading(false);
      }
    } else if (formattedTitle === "View") {
      setFormattedTitle("Edit");
    } else {
      setIsButtonLoading(true);
      try {
        const data = {
          modelSku: {
            commonName: formData?.commonName,
            kitt: formData?.kitt,
            productId: formData?.productId,
            sku: formData?.sku,
            upc: formData?.upc,
            name: formData?.name,
            type: formData?.type,
          },
          type: "Model/SKU"
        };
        const response: any = await addSalesEntry(data);
        console.log("Sales Entry Updated:", response.data);
        setIsButtonLoading(false);
        setActiveIndex(1);
        navigate("/sales");
      } catch (error) {
        console.error("Error updating sales entry:", error);
        setIsButtonLoading(false);
      }
    }

  };

  const handleBack = () => {
    navigate('/sales')
    setActiveIndex(1);
  }

  const firstPart = path?.split('-')[0];

  useEffect(() => {
    setFormattedTitle(
      firstPart ? firstPart.charAt(0).toUpperCase() + firstPart.slice(1) : ""
    );
  }, [])

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        const response: any = await getSalesEntryDetail(salesId);
        setFormData(response.data?.modelSku);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching sales entry detail:", error);
        setIsLoading(false);
      }
    };

    if ((formattedTitle === 'View' || formattedTitle === 'Edit') && salesId) {
      setIsLoading(true);
      fetchDetail();
    }
  }, [formattedTitle, salesId]);

  const fetchPlanType = async () => {
    try {
      const response: any = await getModelSKUTypes();
      setModelTypes(response.data);
    } catch (error) {
      console.error("Error fetching plan types:", error);
    }
  };
  const fetchKittType = async () => {
    try {
      const response: any = await getKittTypes();
      setKittTypes(response.data);
    } catch (error) {
      console.error("Error fetching plan types:", error);
    }
  };
  
  useEffect(() => {
    fetchPlanType();
    fetchKittType();
  }, [dropDownOpen]);

  const handleAddType = async() =>{
    if (!formData.addType) {
      toast.error("Please enter type.");
      return;
    }
    setIsTypeLoading(true);
    try {
      const data = {
        modelSkuType: formData.addType,
      }
      const response: any = await addModelTypes(data);
      setIsTypeLoading(false);
      fetchPlanType();
      setDropDownOpen(false);
      setFormData((prevFormData) => ({
        ...prevFormData,
        addType: '',
      }));
    } catch (error) {
      console.error("Error fetching sales entry detail:", error);
      setIsTypeLoading(false);
    }
  }

  const handleAddKittType = async() =>{
    if (!formData.addKittType) {
      toast.error("Please enter kitt type.");
      return;
    }
    setIsTypeLoading(true);
    try {
      const data = {
        kittType: formData.addKittType,
      }
      const response: any = await addKittTypes(data);
      setIsTypeLoading(false);
      fetchKittType();
      setKittDropDownOpen(false);
      setFormData((prevFormData) => ({
        ...prevFormData,
        addKittType: '',
      }));
    } catch (error) {
      console.error("Error fetching sales entry detail:", error);
      setIsTypeLoading(false);
    }
  }

  return (
    <Fragment>
      <Header title={`${formattedTitle} Model/SKU`} backButton={true} backHandle={handleBack} admin={true} />
      <Toaster/>
      <form className="loaction-list-main add-plan-main" onSubmit={handleSubmit}>
        <div className="loaction-list-head">
          <h3>Model/SKU Details</h3>
          <button type="submit" className="add-service">{isButtonLoading ? <Loading /> : `${formattedTitle === "View" ? "Edit" : "Save"}`}</button>
        </div>
        {isLoading ? <PageLoading /> :
          <div className={`add-plan-detail ${formattedTitle === "Edit" || formattedTitle === "Add"  ? "active" : ""}`}>
            <div className="input-group">
              <div className="input-field">
                <label>Common Name</label>
                <input
                  type="text"
                  name="commonName"
                  placeholder="TW - iPhone 11 64GB"
                  value={formData?.commonName}
                  onChange={handleChange}
                  disabled={formattedTitle === "View"}
                />
              </div>
              <div className="input-field">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Apple iPhone 11 64GB Black TBV"
                  value={formData?.name}
                  onChange={handleChange}
                  disabled={formattedTitle === "View"}
                />
              </div>
              <div className="input-field">
                <label>SKU</label>
                <input
                  type="text"
                  name="sku"
                  placeholder="TVAPI11C64BKV4P"
                  value={formData?.sku}
                  onChange={handleChange}
                  disabled={formattedTitle === "View"}
                />
              </div>
              <div className="input-field">
                <label>UPC</label>
                <input
                  type="text"
                  name="upc"
                  placeholder="194252788844"
                  value={formData?.upc}
                  onChange={handleChange}
                  disabled={formattedTitle === "View"}
                />
              </div>
              <div className="input-field">
                <label>Product ID</label>
                <input
                  type="text"
                  name="productId"
                  placeholder="18502"
                  value={formData?.productId}
                  onChange={handleChange}
                  disabled={formattedTitle === "View"}
                />
              </div>
              <div className="input-field">
                <label>Kitt</label>
                <button type="button" className={`${formattedTitle === "Edit" || formattedTitle === "Add"  ? "active" : ""}`} onClick={() => {
                  if (formattedTitle === "View") return;
                  setKittDropDownOpen(!kittDropDownOpen)
                  setDropDownOpen(false);
                }}>
                  {formData.kitt || "Select"}{formattedTitle !=="View" && <img src="/images/angle_down_grey.svg" alt="Toggle" />}</button>
                <div className={`carrier-search-main ${kittDropDownOpen ? "active" : ''}`} ref={dropdownKittRef}>
                  <div className="search-bar">
                    <input
                      type="search"
                      placeholder="Enter New $00.00"
                      name="addKittType"
                      value={formData?.addKittType}
                      onChange={handleChange}
                      disabled={formattedTitle === "View"}
                    />
                    <button type="button" name="addKittBtn" onClick={handleAddKittType}>{isTypeLoading ?<Loading/> :"+Add"}</button>
                  </div>
                  <ul>
                    {kittTypes.map((type, index) => (
                      <li key={index}
                        onClick={() => {
                          setFormData({ ...formData, kitt: type })
                          setKittDropDownOpen(false)
                        }}
                      >{type}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="input-field">
                <label>Type</label>
                <button type="button" className={`${formattedTitle === "Edit" || formattedTitle === "Add"  ? "active" : ""}`} onClick={() => {
                  if (formattedTitle === "View") return;
                  setDropDownOpen(!dropDownOpen);
                  setKittDropDownOpen(false);
                }}>
                  {formData.type || "Select"}{formattedTitle !=="View" && <img src="/images/angle_down_grey.svg" alt="Toggle" />}</button>
                <div className={`carrier-search-main ${dropDownOpen ? "active" : ''}`} ref={dropdownRef}>
                  <div className="search-bar">
                    <input
                      type="search"
                      placeholder="Enter New Type"
                      name="addType"
                      value={formData?.addType}
                      onChange={handleChange}
                      disabled={formattedTitle === "View"}
                    />
                    <button type="button" name="addBtn"  onClick={handleAddType}>{isTypeLoading ?<Loading/> :"+Add"}</button>
                  </div>
                  <ul>
                    {modelTypes.map((type, index) => (
                      <li key={index}
                        onClick={() => {
                          setFormData({ ...formData, type: type })
                          setDropDownOpen(false)
                      }}
                      >{type}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>}
      </form>
    </Fragment>
  );
};

export default AddModelForm;
