import axios from "axios";
import { getToken } from "../utils/common";

// import { getToken } from "../utils/common";

// const API_URL = "https://zn2j5663-5073.inc1.devtunnels.ms/";
// const API_URL = "https://a65kvsczmw.us-east-2.awsapprunner.com/";
const API_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer${token}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: any) => {
    return response.data;
  },
  (error: any) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      return Promise.reject({ status: 403 });
    }
    return Promise.reject(error?.response?.data);
  }
);

export const postRequest = <ReqData, ResData>(
  slug: string,
  data: ReqData
): Promise<ResData> => {
  return axiosInstance.post(slug, data);
};

export const getRequest = <ResData>(slug: string): Promise<ResData> => {
  return axiosInstance.get(slug);
};

export const deleteRequest = (slug: string) => {
  return axiosInstance.delete(slug);
};

export const putRequest = <ReqData, ResponseData>(
  slug: string,
  data?: ReqData
): Promise<ResponseData> => {
  return axiosInstance.put(slug, data);
};

// Add a specialized function for FormData
export const putFormDataRequest = <ResData>(
  slug: string,
  formData: FormData
): Promise<ResData> => {
  const formDataHeaders = {
    "Content-Type": "multipart/form-data",
  };

  return axiosInstance.put(slug, formData, { headers: formDataHeaders });
};
// Add a specialized function for FormData
export const postFormDataRequest = <ResData>(
  slug: string,
  formData: FormData
): Promise<ResData> => {
  const formDataHeaders = {
    "Content-Type": "multipart/form-data",
  };

  return axiosInstance.post(slug, formData, { headers: formDataHeaders });
};

export const createIncident = (data: any) => {
  return postFormDataRequest("/customer/incidents/add-incident", data);
};
export const getWebsiteIncident = (
  page: any,
  perPage: any,
  incidentType: any
) => {
  return getRequest(
    `/customer/incidents/get-incidents?page=${page}&perPage=${perPage}&incidentType=${incidentType}`
  );
};
export const updateCustomerData = (data: any) => {
  return putFormDataRequest(`/customerAdmin/profile/update-customer`, data);
};
export const addUser = (data: any) => {
  return postFormDataRequest(`/admin/websiteUsers/add-user`, data);
};
export const getProfileData = () => {
  return getRequest(`/customerAdmin/profile/get-customer-data`);
};
export const loginRequest = (data: any) => {
  return postRequest(`/customerAdmin/login`, data);
};
export const updateIncident = (incident: string, data: any) => {
  return putRequest(
    `/admin/incidents/update-incident?incidentId=${incident}`,
    data
  );
};

export const getBrandType = () => {
  return getRequest(`/customer/get-brands-types`);
};
export const signUp = (data: any) => {
  return postRequest(`/customer/signup`, data);
};
export const customerVerifyOtp = (data: any) => {
  return postRequest(`/customer/verify-otp-for-signup`, data);
};

export const verifyEmail = async (data: any) => {
  return postRequest("/customerAdmin/forgot-password", data);
};
export const resendOtp = async (data: any) => {
  return postRequest("/customerAdmin/resend-otp", data);
};

export const verifyOtp = async (data: any) => {
  return postRequest("/customerAdmin/verify-otp", data);
};
export const forgotPassword = async (data: any) => {
  return putRequest("/customerAdmin/reset-password", data);
};
export const changePasswordRequest = async (data: any) => {
  return putRequest("/customerAdmin/profile/change-password", data);
};
export const getWebsiteUser = async (page: any, perPage: any, search: any) => {
  return getRequest(
    `/admin/websiteUsers/get-website-users?page=${page}&perPage=${perPage}&search=${search}`
  );
};
export const getAdminIncident = async (
  page: any,
  perPage: any,
  search: any,
  role: string,
  type: string,
  brand: string,
  zipCode: string,
  fromDate: string,
  toDate: string
) => {
  return getRequest(
    `/admin/incidents/get-incidents?page=${page}&perPage=${perPage}&search=${search}&role=${role}&type=${type}&brand=${brand}&zipCode=${zipCode}&fromDate=${fromDate}&toDate=${toDate}`
  );
};
export const addPermission = async (data: any, userId: string) => {
  return postFormDataRequest(
    `/admin/websiteUsers/add-permissions?userId=${userId}`,
    data
  );
};
export const getIncidentDetails = async (incidentId: string) => {
  return getRequest(
    `/admin/incidents/get-incident-detail?incidentId=${incidentId}`
  );
};
export const getSheets = async () => {
  return getRequest(`/admin/get-sheets-data`);
};
export const getCsvFile = async () => {
  return getRequest(`/admin/incidents/export-incidents`);
};
export const grammarPrompt = async (prompt: string) => {
  return postRequest(`/fix-grammer`, { prompt });
};
export const getPlanListing = async (page: any, perPage: any, type: string) => {
  return getRequest(
    `/admin/salesEntry/listing?page=${page}&perPage=${perPage}&salesEntryType=${type}`
  );
};
export const getSalesEntryDetail = async (id: string) => {
  return getRequest(`/admin/salesEntry/detail?salesEntryId=${id}`);
};
export const updateSalesEntryDetail = async (id: string, data: any) => {
  return putRequest(`/admin/salesEntry/edit?salesEntryId=${id}`, data);
};
export const addSalesEntry = async (data: any) => {
  return postRequest(`/admin/salesEntry/add`, data);
};
export const deletePort = async (id: any) => {
  return deleteRequest(`/admin/salesEntry/delete?salesEntryId=${id}`);
};
export const getModelSKUTypes = async () => {
  return getRequest(`/admin/salesEntry/get-modelSkuTypes`);
};
export const getKittTypes = async () => {
  return getRequest(`/admin/salesEntry/get-kitt`);
};
export const getPlanTypes = async () => {
  return getRequest(`/admin/salesEntry/get-planTypes`);
};
export const getPortTypes = async () => {
  return getRequest(`/admin/salesEntry/get-portTypes`);
};
export const addWebSalesEntry = async (data: any) => {
  return postRequest(`/customer/sales/add-sales`, data);
};

export const getWebPlans = async (planSearchString: string) => {
  return getRequest(`/customer/sales/get-plans?search=${planSearchString}`);
};
export const getWebPort = async (searchString: string) => {
  return getRequest(`/customer/sales/get-ports?search=${searchString}`);
};
export const getWebModel = async (searchString: string) => {
  return getRequest(`/customer/sales/get-modelSkus?search=${searchString}`);
};
export const getWebCarrier = async (searchString: string) => {
  return getRequest(`/customer/sales/get-carriers?search=${searchString}`);
};
export const addPlanTypes = async (data: any) => {
  return postRequest(`/admin/salesEntry/add-planTypes`, data);
};
export const addModelTypes = async (data: any) => {
  return postRequest(`/admin/salesEntry/add-modelSkuTypes`, data);
};
export const addKittTypes = async (data: any) => {
  return postRequest(`/admin/salesEntry/add-kitt`, data);
};

export const addPortTypes = async (data: any) => {
  return postRequest(`/admin/salesEntry/add-portTypes`, data);
};

export const getWebSales = async (
  page: any,
  perPage: any,
  storeName: string
) => {
  return getRequest(
    `/customer/sales/listing?page=${page}&perPage=${perPage}&storeName=${storeName}`
  );
};
// const baseURL = "https://us-east-1.aws.data.mongodb-api.com/app/application-0-llfhjoe/endpoint/"

// export const axiosInstance = axios.create({
//     baseURL:baseURL
// })

export const getSalesMembers = async () => {
  return axiosInstance.get("/getSalesMember");
};
export const registerSalesMember = async (data: any) => {
  return axiosInstance.post("/registerSalesMember", data);
};

export const updateProfile = async (data: any) => {
  return axiosInstance.put("/updateProfileData", data);
};
export const removeSaleMember = async (id: any) => {
  return axiosInstance.delete(`/removeSaleMember?id=${id}`);
};
export const addExcelSalesEntry = async (data: any) => {
  return postFormDataRequest(`/admin/salesEntry/add-plans-from-excel`, data);
};

export const addSKUSalesEntry = async (data: any) => {
  return postFormDataRequest(
    `/admin/salesEntry/add-modelSkus-from-excel`,
    data
  );
};

export const swapSalesEntries = async (swapId: any, swappedWith: any) => {
  return axiosInstance.post(
    `/admin/salesEntry/swap-serialNumber?salesEntryIds=${swapId},${swappedWith}`
  );
};
