import Header from "../../components/common/Header";
import { Link } from "react-router-dom";

const SalesEntryWeb = () =>{
    return(
        <>
                <Header title={"Sale Entry"} backButton={null} backHandle={null} admin={false}/>
                <div className="loaction-list-main col-boxes-main">
                <div className="col-boxes">
                    <Link to='/new-activation'>
                    <div className="col-item">
                        <b>New Activation</b>
                    </div>
                    </Link>
                    <div className="col-item">
                        <b>Upgrade</b>
                    </div>
                    <Link to='/add-line'>
                    <div className="col-item">
                        <b>Add a Line</b>
                    </div>
                    </Link>
                    <div className="col-item">
                        <b>Device Only</b>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SalesEntryWeb;