import React, {  createContext, useEffect, useState } from 'react';
import { getProfileData } from '../config/api';

// Create the ThemeContext
const AppContext = createContext< any>(undefined);

// Create a provider component
export const AppProvider = ({ children }:{children:any}) => {
    const [mobileToggle , setMobileToggle] = useState(false)
    const [profileData , setProfileData] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0);
    const[salesId, setSalesId]= useState(null);
  
 

  return (
    <AppContext.Provider value={{ mobileToggle , setMobileToggle , profileData , setProfileData, activeIndex, setActiveIndex, salesId, setSalesId}}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
