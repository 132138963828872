import Loading from "../common/Loading";

const Confirmation = ({title , cancelHandle , isLoading ,  submitHandle , btnTitle}:{title:string , isLoading:boolean ,cancelHandle:()=>void , submitHandle:()=>void  , btnTitle:string}) => {
  return (
    <div className='overlay'>
        <div className="logout-main">
        <button className="delete-icon"><img src="/images/logout_icon.svg" alt="" /></button>
        <h2>Please Confirm!</h2>
        <p>{title}</p>
        <div className="block-btn">
            <button className="cancle-btn" onClick={cancelHandle}>Cancel</button>
            <button className="logout-btn" onClick={submitHandle}>{isLoading ? <Loading/> : `${btnTitle}`}</button>
        </div>
    </div>
      
    </div>
  );
}

export default Confirmation;
