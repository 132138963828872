import  { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Confirmation from '../popUps/Confirmation';
import ProfileSection from './ProfileSection';
import { useApplication } from '../../test/useApplication';
import AppContext from '../../context/AppProvider';
import back_icon from "../../assets/images/back_icon.svg";
import bar_icon from "../../assets/images/bar_icon.svg";

const Header = ({title , backButton , backHandle , admin}:{title:string , backButton:any , backHandle:any , admin:boolean}) => {


     const {setMobileToggle , profileData} = useContext(AppContext)
  


    const [formData, setFormData] = useState<any>({
        firstName: '',
        lastName: '',
        number: '',
        email:"",
        image:""
    });




useEffect(() => {
    if(profileData){
      setFormData({...formData , firstName:profileData?.firstName , lastName:profileData?.lastName , image:profileData?.image , email:profileData?.email})
    }
    
}, [profileData])





    const navigate = useNavigate()



    const handleProfile = () =>{
        if(admin){
            navigate("/setting")

        }else{
            navigate('/setting-profile')
        }
    }
 
  return (
    <>
    <div className="dashboard-header">
              <div className="bar-btn-mobile">
                  <span onClick={()=>setMobileToggle(true)}><img src={bar_icon} alt="" /></span>
              </div>
              <div className="dashboard-heading">
            <a className="dashboard-back"> {backButton && <img src={back_icon} alt="" style={{cursor:"pointer"}} onClick={backHandle} />  }  {title}</a>
              </div>
              <div className="logo-mobile">
                  <a style={{cursor:"pointer"}}><img src="/images/Logo.png" alt="" /></a>
              </div>
              <ProfileSection formData={formData} handleProfile={handleProfile} />
          </div>
    </>
  );
}

export default Header;
