import React, {
  useState,
  Fragment,
  useContext,
  useEffect,
  useRef,
} from "react";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import AppContext from "../../context/AppProvider";
import {
  addPortTypes,
  addSalesEntry,
  getPortTypes,
  getSalesEntryDetail,
  updateSalesEntryDetail,
} from "../../config/api";
import PageLoading from "./PageLoading";
import moment from "moment";
import Loading from "./Loading";
import toast, { Toaster } from "react-hot-toast";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

const AddPortForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    startDate: null,
    endDate: null,
    addType: "",
  });
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const path = location.pathname.split("/").pop();
  const [portTypes, setPortTypes] = useState([]);
  const [isTypeLoading, setIsTypeLoading] = useState(false);
  const [formattedTitle, setFormattedTitle] = useState<string>("");
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { setActiveIndex, salesId } = useContext(AppContext);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropDownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const navigate = useNavigate();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date: Dayjs | null, field: string) => {
    console.log("Received date:", date);
    const formattedDate = date ? dayjs(date).format("MM/DD/YYYY") : null;
    setFormData((prevData) => ({
      ...prevData,
      [field]: formattedDate,
    }));
  };

  const validateForm = () => {
    if (!formData?.name || formData?.name.trim() === "") {
      toast.error("Name is required.");
      return false;
    }

    if (!formData?.type || formData?.type.trim() === "") {
      toast.error("Type is required.");
      return false;
    }

    if (!formData?.startDate) {
      toast.error("Start date is required.");
      return false;
    }

    if (formData?.endDate) {
      const startDate = new Date(formData.startDate);
      const endDate = new Date(formData.endDate);

      if (endDate <= startDate) {
        toast.error("End date must be greater than start date.");
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (formattedTitle === "Edit") {
      setIsButtonLoading(true);
      // const formatEndDate = formData?.endDate
      //   ? moment(formData?.endDate).format("MM/DD/YYYY")
      //   : "";
      // const formatStartDate = formData?.startDate
      //   ? moment(formData?.startDate).format("MM/DD/YYYY")
      //   : "";
      const formatDate = (date: string | null) => {
        return date ? moment(date).format("MM/DD/YYYY") : null;
      };
      try {
        const data = {
          port: {
            endDate: formatDate(formData.endDate),
            name: formData?.name,
            startDate: formatDate(formData.startDate),
            type: formData?.type,
          },
          type: "Port",
        };
        const response: any = await updateSalesEntryDetail(salesId, data);
        console.log("Sales Entry Updated:", response.data);
        setIsButtonLoading(false);
        setActiveIndex(3);
        navigate("/sales");
      } catch (error) {
        console.error("Error updating sales entry:", error);
        setIsButtonLoading(false);
      }
    } else if (formattedTitle === "View") {
      setFormattedTitle("Edit");
    } else {
      setIsButtonLoading(true);
      const formatEndDate = formData?.endDate
        ? moment(formData?.endDate).format("MM/DD/YYYY")
        : "";
      const formatStartDate = formData?.startDate
        ? moment(formData?.startDate).format("MM/DD/YYYY")
        : "";

      try {
        const data = {
          port: {
            endDate: formData?.endDate ? formData?.endDate : null,
            name: formData?.name,
            startDate: formData?.startDate,
            type: formData?.type,
          },
          type: "Port",
        };
        const response: any = await addSalesEntry(data);
        console.log("Sales Entry added:", response.data);
        setIsButtonLoading(false);
        setActiveIndex(3);
        navigate("/sales");
      } catch (error) {
        console.error("Error added sales entry:", error);
        setIsButtonLoading(false);
      }
    }
  };

  const handleBack = () => {
    navigate("/sales");
    setActiveIndex(3);
  };

  const firstPart = path?.split("-")[0];

  useEffect(() => {
    setFormattedTitle(
      firstPart ? firstPart.charAt(0).toUpperCase() + firstPart.slice(1) : ""
    );
  }, []);

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        const response: any = await getSalesEntryDetail(salesId);
        const { name, type, startDate, endDate } = response.data.port;
        setFormData({
          name: name || "",
          type: type || "",
          startDate: startDate,
          endDate: endDate ? endDate : "",
          addType: "",
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching sales entry detail:", error);
        setIsLoading(false);
      }
    };

    if ((formattedTitle === "View" || formattedTitle === "Edit") && salesId) {
      setIsLoading(true);
      fetchDetail();
    }
  }, [formattedTitle, salesId]);

  const fetchPlanType = async () => {
    try {
      const response: any = await getPortTypes();
      setPortTypes(response.data);
    } catch (error) {
      console.error("Error fetching plan types:", error);
    }
  };

  useEffect(() => {
    fetchPlanType();
  }, []);

  const handleAddType = async () => {
    if (!formData.addType) {
      toast.error("Please enter type.");
      return;
    }
    setIsTypeLoading(true);
    try {
      const data = {
        portType: formData.addType,
      };
      const response: any = await addPortTypes(data);
      setIsTypeLoading(false);
      fetchPlanType();
      setDropDownOpen(false);
      setFormData((prevFormData) => ({
        ...prevFormData,
        addType: "",
      }));
    } catch (error) {
      console.error("Error fetching sales entry detail:", error);
      setIsTypeLoading(false);
    }
  };

  return (
    <Fragment>
      <Header
        title={`${formattedTitle} Port`}
        backButton={true}
        backHandle={handleBack}
        admin={true}
      />
      <Toaster />
      <form
        className="loaction-list-main add-plan-main"
        onSubmit={handleSubmit}
      >
        <div className="loaction-list-head">
          <h3>Port Details</h3>
          <button type="submit" className="add-service">
            {isButtonLoading ? (
              <Loading />
            ) : (
              `${formattedTitle === "View" ? "Edit" : "Save"}`
            )}
          </button>
        </div>
        {isLoading ? (
          <PageLoading />
        ) : (
          <div
            className={`add-plan-detail ${
              formattedTitle === "Edit" || formattedTitle === "Add"
                ? "active"
                : ""
            }`}
          >
            <div className="input-group">
              <div className="input-field">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Total Base 5G Unlimited"
                  value={formData?.name}
                  onChange={handleChange}
                  disabled={formattedTitle === "View"}
                />
              </div>
              <div className="input-field">
                <label>Type</label>
                <button
                  type="button"
                  className={`${
                    formattedTitle === "Edit" || formattedTitle === "Add"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    if (formattedTitle === "View") return;
                    setDropDownOpen(!dropDownOpen);
                  }}
                >
                  {formData.type || "Select"}
                  {formattedTitle !== "View" && (
                    <img src="/images/angle_down_grey.svg" alt="Toggle" />
                  )}
                </button>
                <div
                  className={`carrier-search-main ${
                    dropDownOpen ? "active" : ""
                  }`}
                  ref={dropdownRef}
                >
                  <div className="search-bar">
                    <input
                      type="search"
                      placeholder="Enter New Type"
                      name="addType"
                      value={formData.addType}
                      onChange={handleChange}
                      disabled={formattedTitle === "View"}
                    />
                    <button type="button" name="addBtn" onClick={handleAddType}>
                      {" "}
                      {isTypeLoading ? <Loading /> : "+Add"}
                    </button>
                  </div>
                  <ul>
                    {portTypes.map((type, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setFormData({ ...formData, type: type });
                          setDropDownOpen(false);
                        }}
                      >
                        {type}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="input-field datepicker-wrapper">
                <label>Start Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={
                      formData.startDate ? dayjs(formData.startDate) : null
                    }
                    onChange={(date) => handleDateChange(date, "startDate")}
                    disabled={formattedTitle === "View"}
                  />
                </LocalizationProvider>
              </div>
              <div className="input-field datepicker-wrapper">
                <label>End Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={formData.endDate ? dayjs(formData.endDate) : null}
                    onChange={(date) => handleDateChange(date, "endDate")}
                    disabled={formattedTitle === "View"}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        )}
      </form>
    </Fragment>
  );
};

export default AddPortForm;
